@import "shared/assets/stylesheets/mixins.scss";
@import "shared/assets/stylesheets/vars.scss";
@import 'shared/assets/stylesheets/core.scss';

.color-bar {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  gap: rem(5);

  &__top {
    height: rem(6);
    background-color: $color-maroon-dark;
    width: 90%;
  }

  &__mid {
    height: rem(6);
    background-color: $color-maroon-dark;
    width: 94%;
  }

  &__bottom {
    height: rem(6);
    background-color: $color-maroon-dark;
    width: 97%;
  }

  &--left {
    flex-direction: column-reverse;
    direction: rtl;
  }
}