@import 'shared/assets/stylesheets/vars.scss';
@import 'shared/assets/stylesheets/mixins.scss';

.hero {
  margin: 0 auto;
  background-color: $color-brand;
  background-position: 0 0;
  background-size: cover;
  color: #fff;
  display: flex;
  width: 100%;
  font-family: $heading-font-family;

  .container {
    margin: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
  }

  &__content {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;
    gap: rem(20);
    height: min-content;
    width: 100%;
    align-self: center;

    @include tablet {
      padding-bottom: rem(20);
    }

    .rte {

      h1,
      h2,
      h3,
      h4,
      h5 {
        line-height: rem(90);

        @include phone {
          line-height: rem(51.2);
          font-size: rem(40);
        }
      }
    }

    h1 {
      font-size: 3rem;
      grid-column: 1;
      font-weight: 400;
      margin-bottom: 0;
    }

    h2 {
      font-size: 1rem;
      font-weight: 400;
      font-style: italic;
      grid-column: 1;
    }

    .button {
      border: 0;
      grid-column: 2;
      grid-row: 2;
      font-size: 0.875rem;
      font-family: $text-font-family;
    }

    &--video {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 rem(100) rem(160);
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      h1 {
        font-size: 3rem;
      }

      @include tablet {
        padding-bottom: rem(20);

        h1 {
          font-size: 2.5rem;
        }
      }

      @include phone {
        padding: 0 rem(20) rem(20);

        h1 {
          font-size: 1.5rem;
        }
      }
    }
  }

  &--large {
    position: relative;

    @include tablet {
      min-height: auto
    }
  }

  &--standard {
    min-height: 27.5rem;
  }

  &__video {
    width: 100%;
    height: 100%;
  }

  &--gradient {
    position: relative;

    &:before {
      content: '';
      background: linear-gradient(to top, transparent 74%, black 122%);
      width: 100%;
      position: absolute;
      height: -webkit-fill-available;
      z-index: 0;
    }

    &:after {
      content: '';
      background: linear-gradient(to bottom, transparent 65%, black 122%);
      width: 100%;
      position: absolute;
      height: 100%;
      z-index: 0;
    }

    .hero__content {
      z-index: 997;
      position: relative;
      /* two beneath the header -- accounts for tabbed nav*/
    }
  }
}