@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

.image-block {
  display: grid;
  justify-content: left;

  &:last-of-type {
    margin-right: 0;
  }

  &__footer {
    width: 100%;
    height: rem(180);
    background: $color-brand;
    background-size: 40rem;
  }

  &--30-70 {
    grid-template-columns: 60% 1fr;
    // align-items: center;
    justify-items: center;
    gap: 4.25rem;

    &__text {
      display: inline-grid;
      grid-template-rows: min-content max-content auto;
      text-align: left;
      grid-gap: 2rem;
      grid-template-columns: auto;
      justify-self: center;
      align-self: center;

      @include tablet {
        max-width: rem(450);
      }

      h2 {
        direction: ltr;
        color: $color-brand;
        font-family: $heading-font-family;
        font-size: rem(40);
        font-style: normal;
        font-weight: 500;
        line-height: rem(60);
        letter-spacing: rem(0.8);
        margin-bottom: 0;
      }

      h3 {
        direction: ltr;
        color: $color-brand;
        margin-bottom: 0;
        font-family: $text-font-family;
        font-size: rem(28);
        font-style: normal;
        font-weight: 600;
        line-height: rem(38);
        text-transform: capitalize;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: rem(42);
    }

    img {
      width: 100%;
      max-height: rem(840);
      border-radius: rem(8);
    }

    @include tablet {
      gap: rem(40);

      &__img {
        grid-row: 2;
      }

      img {
        max-width: rem(390);
        height: auto;
      }
    }
  }

  &--40-60 {
    grid-template-columns: 1fr 1fr;
    // align-items: center;
    justify-items: center;
    gap: rem(100);

    &__text {
      display: inline-grid;
      grid-template-rows: min-content max-content auto;
      text-align: left;
      grid-gap: 2rem;
      grid-template-columns: auto;
      justify-self: center;
      align-self: center;

      @include tablet {
        max-width: rem(450);
      }

      h2 {
        direction: ltr;
        color: $color-brand;
        font-family: $heading-font-family;
        font-size: rem(40);
        font-style: normal;
        font-weight: 500;
        line-height: rem(60);
        letter-spacing: rem(0.8);
        margin-bottom: 0;
      }

      h3 {
        direction: ltr;
        color: $color-brand;
        margin-bottom: 0;
        font-family: $text-font-family;
        font-size: rem(28);
        font-style: normal;
        font-weight: 600;
        line-height: rem(38);
        text-transform: capitalize;
      }

      p {
        direction: ltr;
        color: $color-black;
        font-family: $text-font-family;
        font-size: rem(16);
        font-style: normal;
        font-weight: 400;
        line-height: rem(28);
      }
    }

    &__img {
      max-width: rem(443);
      height: auto;
    }

    img {
      width: 100%;
      height: auto;
      border-radius: rem(8);
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: rem(42);
    }
  }

  &--50-50 {
    .image-block {
      justify-content: unset;
    }

    grid-template-columns: 1fr 1fr;
    // align-items: center;
    justify-items: center;
    gap: rem(100);

    &__text {
      display: inline-grid;
      grid-template-rows: min-content max-content auto;
      grid-gap: rem(10);
      grid-template-columns: auto;
      justify-self: center;
      align-self: center;

      @include tablet {
        max-width: rem(450);
      }

      h2 {
        direction: ltr;
        color: $color-maroon-dark;
        font-family: $heading-font-family;
        font-size: rem(36);
        font-style: normal;
        font-weight: 700;
        line-height: rem(44);
        letter-spacing: rem(0.8);
        margin-bottom: 0;
      }

      h3 {
        direction: ltr;
        color: $color-brand;
        margin-bottom: 0;
        font-family: $text-font-family;
        font-size: rem(28);
        font-style: normal;
        font-weight: 600;
        line-height: rem(38);
        text-transform: capitalize;
      }

      p {
        direction: ltr;
        font-family: $text-font-family;
        font-size: rem(16);
        font-style: normal;
        font-weight: 400;
        line-height: rem(10);
        max-width: rem(607);
      }
    }

    &__img {
      height: auto;

      @include phone {
        max-width: rem(349);
        max-height: rem(296);
      }
    }

    &__rte {
      max-width: rem(600);
      width: 100%;
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: rem(42);
    }

    img {
      width: 100%;
      border-radius: rem(0);
    }

    @include tablet {
      gap: rem(50);
    }
  }

  &--reversed {
    direction: rtl;

    &__text {
      display: inline-grid;
      grid-template-rows: min-content max-content auto;
      grid-gap: rem(10);
      grid-template-columns: auto;
      justify-self: center;
      align-self: center;
      margin-left: rem(10);

      @include tablet {
        max-width: rem(450);
      }

      h2 {
        direction: ltr;
        color: $color-maroon-dark;
        font-family: $heading-font-family;
        font-size: rem(36);
        font-style: normal;
        font-weight: 700;
        line-height: rem(44);
        letter-spacing: rem(0.8);
        margin-bottom: 0;
      }

      h3 {
        direction: ltr;
        color: $color-brand;
        margin-bottom: 0;
        font-family: $text-font-family;
        font-size: rem(28);
        font-style: normal;
        font-weight: 600;
        line-height: rem(38);
        text-transform: capitalize;
      }

      p {
        direction: ltr;
        font-family: $text-font-family;
        font-size: rem(16);
        font-style: normal;
        font-weight: 400;
        line-height: rem(10);
        max-width: rem(607);
      }
    }
  }

  &--color-brand {
    background-color: $color-brand;
    color: $color-white;

    .button {
      background-color: $color-white;
      color: $color-brand;
    }
  }

  &--color-azureish-white {
    background-color: $color-azureish-white;
    color: $color-brand;

    .button {
      background-color: $color-brand;
      color: $color-white;
    }
  }

  &--color-dark-blue-gray {
    background-color: $color-dark-blue-gray;
    color: $color-white;

    .button {
      background-color: $color-white;
      color: $color-brand;
    }
  }

  &--color-malibu-blue {
    background-color: $color-malibu-blue;
    color: $color-brand;

    .button {
      background-color: $color-brand;
      color: $color-white;
    }
  }

  &--color-beige {
    background-color: $color-beige;
    color: $color-brand;

    .button {
      background-color: $color-brand;
      color: $color-white;
    }
  }

  @include tablet {
    grid-template-columns: 1fr;
    justify-content: left;
  }

  &--three-photo-collage {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__content,
    &__content--rtl {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 0.625rem;
    }

    &__first-image,
    &__second-image,
    &__third-image {
      width: 21.875rem;
      height: 22rem;
      background-size: cover;
      background-position: center center;
      margin-top: 0.625rem;
      border-radius: 0.5rem;
    }


    &__container-position,
    &__container-position--rtl {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__button-position {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: inherit;
    }
  }

  @include after-phone {
    &--three-photo-collage {
      display: flex;
      flex-direction: column;
      align-items: normal;

      &__container-position {
        display: flex;
        flex-direction: row;
        max-width: 100%;
        gap: 1.311rem;
        justify-content: center;
        align-items: end;
        padding-bottom: 6.25rem;
      }

      &__container-position--rtl {
        display: flex;
        flex-direction: row;
        max-width: 100%;
        gap: 1.311rem;
        justify-content: center;
        align-items: end;
        padding-bottom: 6.25rem;
        direction: rtl;
      }

      &__content {
        display: flex;
        position: relative;
        top: 8.625rem;
        flex-direction: row;

        >div {
          width: 42.668rem;
        }
      }

      &__content--rtl {
        display: flex;
        width: 65%;
        position: relative;
        top: 8.625rem;
        flex-direction: row;

        >div {
          width: 14.668rem;
        }
      }

      &__first-image,
      &__second-image,
      &__third-image {
        margin-top: 0rem;
      }

      &__first-image {
        width: 28.668rem;
        height: 35.438rem;
      }

      &__second-image {
        width: 21.189rem;
        height: 20.438rem;
      }

      &__third-image {
        width: 36.146rem;
        height: 20.313rem;
      }
    }
  }
}

.fontColor {
  &--brand {
    color: $color-brand;

    p {
      color: $color-black;
    }
  }

  &--white {
    color: $color-white;
  }
}