@import 'shared/assets/stylesheets/core.scss';

.columnCards {
  // Mobile-first approach

  .container {
    padding-right: 0;
    padding-bottom: rem(40);
  }

  .columnGrid {
    display: flex;
    flex-wrap: nowrap; // Cards overflow horizontally on mobile
    overflow-y: hidden;
    gap: rem(60);
    padding-bottom: rem(60);

    &--horizontalImage {
      padding-bottom: 0;
    }

    &__default {
      display: none;
    }

    &::-webkit-scrollbar-track {
      margin-right: rem(20);
    }

    &:last-child {
      padding-right: rem(20);
    }

    .column {
      text-align: left;

      .img-wrapper {
        max-width: 100%;
        height: auto;
        overflow: hidden;

        img {
          border-radius: rem(0);
          height: rem(250);
          width: 100%;
          object-fit: cover;
        }
      }
    }

    &--horizontalImage {
      display: flex;
      flex-direction: column;

      .column {
        width: 100%;
        margin-bottom: rem(16);
      }

      .img-wrapper {
        flex-shrink: 0;
        max-width: rem(418);
        height: rem(265);
      }
    }

    &--threeColumnImage,
    &--fourColumnImage {
      .column {
        min-width: rem(318);

        &--carousel {
          min-width: rem(0);
        }
      }

      .img-wrapper {
        height: rem(265);
        width: fit-content;
      }
    }

    &--threeColumnImage {
      grid-template-columns: repeat(3, 1fr);
      gap: rem(60);
    }

    &--fourColumnImage {
      grid-template-columns: repeat(4, 1fr);
      gap: rem(35);
    }

    &--fiveColumnImage {
      gap: rem(32);
      grid-template-columns: repeat(5, 1fr);

      .img-wrapper {
        height: rem(230);
      }

      .column {
        min-width: rem(250);
        height: 100%;
      }

      .cta2 {
        display: block;
      }
    }

    &--icon {
      .img-wrapper {
        height: fit-content;
        width: rem(125);

        img,
        svg {
          object-fit: contain;
        }
      }
    }

  }

  .content {
    padding: rem(22);
    height: 100%;

    h3,
    p,
    a {
      margin-top: rem(16);
    }

    h3 {
      font-family: $text-font-family;
      font-size: rem(28);
      font-weight: 600;
      line-height: 135.714%;
      text-transform: capitalize;
      color: $color-brand;
    }

    p {
      font-family: $text-font-family;
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      line-height: 175%;
    }

    .cta1 {
      margin-right: rem(20);
    }
  }

  &__carousel-container {
    display: block;
    overflow-x: hidden;
    align-items: center;
    padding-right: rem(20);
    padding-top: rem(20);

    img {
      border-radius: 0.5rem;
    }

    &__dot-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.813rem;
      margin-top: rem(20);
    }

    .carousel__dot {
      border-radius: 50%;
      width: rem(15);
      height: rem(15);
      border: none;
      box-shadow: 0rem 0.125rem .3rem 0rem rgba(0, 0, 0, .3);
      margin: .4rem;
      padding-right: 0;
    }

    .carousel__dot--selected {
      background-color: $color-gray;
    }
  }

  // Desktop styles
  @include after-tablet {
    padding-right: rem(10);

    &__carousel-container {
      display: none;
    }

    .columnGrid {
      display: grid;
      padding-bottom: 0;

      &__mobile {
        display: none;
      }

      &__default {
        display: flex;
      }

      &--threeColumnImage,
      &--fourColumnImage {
        .column {
          min-width: auto;
        }
      }

      &--horizontalImage {
        .column {
          display: flex;
          flex-direction: row;

          .img-wrapper {
            width: rem(418);
            margin-right: rem(32);
          }

          img {
            object-fit: cover;
            width: 100%;
          }
        }
      }

      &--icon {
        .column {
          .img-wrapper {
            width: rem(125);
          }
        }
      }
    }
  }
}