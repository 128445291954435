@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

.collage {
  position: relative;

  h2 {
    font-family: Bitter;
    font-size: rem(40);
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    letter-spacing: rem(0.8);
  }

  p {
    font-family: Open Sans;
    font-size: rem(16);
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
  }

  &__mobile {
    margin-bottom: rem(20);
  }

  &__text {
    align-self: flex-start;
  }

  &--left {
    .collage__text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: rem(30);
      margin-top: rem(-120);
      padding: 0 rem(100) rem(100) rem(100);
      max-width: rem(690);

      h2 {
        margin: 0;
      }

      @include tablet {
        margin-top: 0;
        padding: 0;
      }
    }
  }

  &--right {
    .collage__text {
      display: flex;
      flex-direction: column;
      gap: rem(30);
      margin-top: rem(-120);
      padding: 0 rem(100) rem(100) rem(100);
      max-width: rem(690);

      h2 {
        margin: 0;
      }

      @include tablet {
        margin-top: 0;
        padding: 0;
      }
    }
  }

  &__row {
    padding: rem(160) 0 rem(90) 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: rem(20);

    @include tablet {
      display: flex !important;
      flex-direction: column;
      gap: rem(50);
    }

    .collage__item {
      display: none;
    }

    .collage__itemMobile {
      display: flex;
      height: rem(352);
    }

    .slide-padding {
      padding: 0 rem(5) 0 rem(5);
    }

    .collage__item,
    .collage__itemMobile {
      position: relative;
      justify-content: center;
      align-items: flex-end;
      align-self: end;
      border-radius: 0.5rem;


      img {
        display: block;
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        border-radius: 0.5rem;

      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg,
            rgba(0, 0, 0, 0.00) 42.71%,
            rgba(0, 0, 0, 0.65) 100%);
        pointer-events: none;
        /* This will ensure the gradient doesn't interfere with clicking on elements underneath */
        border-radius: 0.5rem;
      }

      @include tablet {
        width: 100%;
      }
    }

    .collage__itemCta {
      z-index: 1;
      margin: 0 rem(20) rem(45) rem(20);
    }

    @include tablet {
      padding: 0;
    }
  }

  &--three-items {
    .collage--left {
      .collage__text {
        grid-row: 1;
        grid-column: 5 / span 8;
      }

      .collage__item {
        overflow: auto;

        &.item--1 {
          grid-column: 1 / span 4;
          grid-row: 1/3;
          height: rem(570);
        }

        &.item--2 {
          grid-column: 5 / span 3;
          grid-row: 2;
          height: rem(325);
        }

        &.item--3 {
          grid-column: 8 / span 5;
          grid-row: 2;
          height: rem(325);
          width: 100%;
        }
      }
    }

    .collage--right {
      .collage__text {
        grid-row: 1;
        grid-column: 1 / span 8;
      }

      .collage__item {
        overflow: auto;

        &.item--1 {
          grid-column: 9 / span 4;
          grid-row: 1/3;
          height: rem(570);
        }

        &.item--2 {
          grid-column: 6 / span 3;
          grid-row: 2;
          height: rem(325);
        }

        &.item--3 {
          grid-column: 1 / span 5;
          grid-row: 2;
          height: rem(325);
        }
      }
    }
  }

  &--four-items {
    .collage--left {
      .collage__text {
        grid-row: 1;
        grid-column: 5 / span 8;
      }

      .collage__item {
        overflow: auto;
        min-height: rem(300);

        &.item--1 {
          grid-column: 1 / span 4;
          grid-row: 1/3;
          height: rem(567);
        }

        &.item--2 {
          grid-column: 5 / span 3;
          grid-row: 2;
          height: rem(326);
        }

        &.item--3 {
          grid-column: 1 / span 5;
          grid-row: 3;
          height: rem(326);
        }

        &.item--4 {
          grid-column: 6 / span 5;
          grid-row: 3;
          height: rem(326);
        }
      }
    }

    .collage--right {
      .collage__text {
        grid-row: 1;
        grid-column: 1 / span 8;
      }

      .collage__item {
        overflow: auto;
        min-height: rem(300);

        &.item--1 {
          grid-column: 9 / span 4;
          grid-row: 1/3;
          height: rem(567);
        }

        &.item--2 {
          grid-column: 6 / span 3;
          grid-row: 2;
          height: rem(326);
        }

        &.item--3 {
          grid-column: 3 / span 5;
          grid-row: 3;
          height: rem(326);
        }

        &.item--4 {
          grid-column: 8 / span 5;
          grid-row: 3;
          height: rem(326);
        }
      }
    }
  }

  &--five-items {
    .collage--left {
      .collage__text {
        grid-row: 1;
        grid-column: 5 / span 8;
      }

      .collage__item {
        overflow: auto;
        min-height: rem(300);

        &.item--1 {
          grid-column: 1 / span 4;
          grid-row: 1/3;
          height: rem(567);
        }

        &.item--2 {
          grid-column: 5 / span 4;
          grid-row: 2;
          height: rem(326);
        }

        &.item--3 {
          grid-column: 1 / span 3;
          grid-row: 3;
          height: rem(326);
        }

        &.item--4 {
          grid-column: 4 / span 4;
          grid-row: 3;
          height: rem(326);
        }

        &.item--5 {
          grid-column: 8 / span 4;
          grid-row: 3;
          height: rem(326);
        }
      }
    }

    .collage--right {

      .collage__text {
        grid-row: 1;
        grid-column: 1 / span 8;
      }

      .collage__item {
        overflow: auto;
        min-height: rem(300);

        &.item--1 {
          grid-column: 9 / span 4;
          grid-row: 1/3;
          height: rem(567);
        }

        &.item--2 {
          grid-column: 5 / span 4;
          grid-row: 2;
          height: rem(326);
        }

        &.item--3 {
          grid-column: 2 / span 4;
          grid-row: 3;
          height: rem(326);
        }

        &.item--4 {
          grid-column: 6 / span 4;
          grid-row: 3;
          height: rem(326);
        }

        &.item--5 {
          grid-column: 10 / span 3;
          grid-row: 3;
          height: rem(326);
        }
      }
    }
  }

  &__bg {
    background-repeat: no-repeat;
    background-size: 35%;
    height: 50%;
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: -1;
    height: rem(330);

    @include tablet {
      height: rem(359);
    }

    &Logo {

      @include tablet {
        background-image: none;
      }
    }
  }

  &__desktop {
    display: none;
  }

  &__dot-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.813rem;
    margin-top: rem(10);
  }

  .carousel__dot {
    border-radius: 50%;
    width: rem(15);
    height: rem(15);
    border: none;
    box-shadow: 0rem 0.125rem .2rem 0rem rgba(0, 0, 0, .3);
    margin: .4rem;
    padding-right: 0;
  }

  .carousel__dot--selected {
    background-color: $color-gray;
  }

  @include after-tablet {
    &__mobile {
      display: none;
    }

    &__desktop {
      display: block;
    }

    .collage__item {
      display: flex;
    }
  }
}