@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

.button {
  display: inline-block;
  border: none;
  color: $color-white;
  padding: 0.75rem 1rem;
  background-color: $color-brand;
  font-family: $text-font-family;
  font-size: rem(14);
  grid-column: 1;
  cursor: pointer;
  text-align: center;
  height: fit-content;
  width: fit-content;
  direction: ltr;

  svg {
    margin-right: 0.5rem;
  }

  &--large {
    padding: rem(20) rem(30);
  }

  &--btn-blue {
    color: $color-white !important;

    &:hover {
      animation: btn-blue .5s forwards;
    }
  }

  &--five-column-table {
    color: $color-white !important;
    width: rem(90);

    &:hover {
      animation: btn-blue .5s forwards;
    }

    @include after-phone {
      width: rem(120);
    }
  }

  &--weldon-blue {
    background-color: $color-weldon-blue;
    color: $color-white;
  }

  &--footer {
    background-color: $color-white;
    color: $color-brand;

    &:hover {
      animation: btn-white .5s forwards;
    }
  }

  &--footer-list {

    &:hover {
      animation: btn-extend-underline-small .1s forwards ease;
    }
  }

  &--icon-only {
    display: inline-block;
    background-color: transparent;
    color: $color-white;
    padding: 0;
    font-size: 1.6rem;

    svg {
      margin-right: 0;
    }
  }

  &--image-card {
    background-color: $color-white;
    color: $color-brand;
    padding: 1.125rem;
    font-family: $text-font-family;
    font-size: 1rem;
    font-weight: 600;
    line-height: 0.875rem;
    text-transform: capitalize;
    padding: rem(20) rem(30);

    &:hover {
      animation: btn-white .5s forwards;
    }
  }

  &--two-column-copy-block {
    padding: 1.25rem 1.875rem;
    font-family: $text-font-family;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    text-transform: capitalize;

    @include phone {
      width: 30%;
    }

    &:hover {
      animation: btn-blue .5s forwards;
    }
  }

  &--btn_icon,
  &--btn-nav {
    background-color: transparent;
    border: 0.063rem solid $color-brand;
    color: $color-brand;
    text-transform: uppercase;

    &:hover {
      animation: btn-transparent .5s forwards;
    }
  }

  &--clear {
    font-family: $text-font-family;
    font-size: rem(28);
    font-weight: 600;
    background-color: transparent;
    text-transform: capitalize;
    line-height: rem(38);
    color: $color-brand;
    margin: 0;
    padding: 0.75rem 0;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: rem(2);
      background-color: $color-dusty-blue;
      transform-origin: center;
      transform: scaleX(0);
      transition: transform 0.3s ease;
    }

    &:hover::after {
      animation: btn-underline 0.3s forwards ease;
    }
  }

  &--selected-option-button {
    display: inline-flex;
    align-items: center;
    background-color: transparent;
    color: $color-brand;
    border: 0.063rem solid $color-brand;
    gap: rem(12);
    font-family: $text-font-family;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.625rem;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    padding: rem(8) rem(14);

    svg {
      margin: 0;
    }

    &:hover {
      animation: btn-transparent .5s forwards;
    }
  }

  &--clear-all-button {
    display: flex;
    align-items: center;
    padding: rem(10) rem(0);
  }

  &--clear-all-button,
  &--news-card-brand {
    border: none;
    background-color: transparent;
    color: $color-brand;
    font-family: $text-font-family;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.625rem;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    width: fit-content;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: rem(2);
      background-color: $color-dusty-blue;
      transform-origin: center;
      transform: scaleX(0);
      transition: transform 0.3s ease;
    }

    &:hover::after {
      animation: btn-underline 0.3s forwards ease;
    }
  }

  &--featured-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;

    svg {
      height: 1.875rem;
    }

    &:hover {
      animation: btn-blue .5s forwards;
    }
  }

  &--media-intro {
    background-color: transparent;
    border: 0.063rem solid $color-brand;
    color: $color-brand;
    text-transform: uppercase;

    &:hover {
      animation: btn-transparent .5s forwards;
    }
  }

  &--news-card-carousel-story {
    display: flex;
    padding: 0.625rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    background: $color-green;
    color: $color-brand;

    &:hover {
      animation: btn-green .5s forwards;
    }
  }

  &--news-card-brand {
    color: $color-brand;
    font-family: $text-font-family;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.563rem;
    background: transparent;
    padding: 0rem;
  }

  &--h1-image-carousel-arrow,
  &--news-card-arrow {
    background-color: $color-white;
    color: $color-brand;
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.15);

    >svg {
      margin-right: 0rem;
    }

    .fa-arrow-right {
      height: inherit;
    }

    .fa-arrow-left {
      height: inherit;
    }
  }

  &--h1-image-carousel-arrow,
  &--news-card-arrow,
  &--image-carousel-50-50-arrow {
    background-color: $color-white;
    color: $color-brand;
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.15);

    >svg {
      margin-right: 0rem;
    }

    .fa-arrow-right {
      height: inherit;
    }

    .fa-arrow-left {
      height: inherit;
    }
  }

  &--news-card-arrow,
  &--image-carousel-50-50-arrow {
    width: 1.875rem;
    height: 1.875rem;
    border: none;

    svg {
      path {
        fill: $color-brand !important;
      }
    }
  }

  &--h1-image-carousel-arrow {
    width: 1.25rem;
    border: none;
  }

  &--image-carousel-50-50-arrow {
    box-shadow: 0rem 0.15rem 1.15rem 0rem rgba(0, 0, 0, 0.15);
  }

  &--image-carousel-50-50-arrow,
  &--news-card-arrow,
  &--h1-image-carousel-arrow,
  &--arrow {
    position: relative;
    overflow: hidden;
    border: rem(1) solid transparent;

    &:hover {
      animation: btn-border .25s ease-in forwards;
    }
  }

  &--photo-collage {
    display: flex;
    width: max-content;
    background-color: $color-white;
    color: $color-brand;
    margin-bottom: 2.5rem;

    &:hover {
      animation: btn-white .5s forwards;
    }
  }

  &--green {
    background-color: $color-green;
    color: $color-brand;
    font-family: $text-font-family;
    font-size: rem(16);
    font-weight: 600;
    text-transform: capitalize;
    padding: rem(15) rem(20) rem(14) rem(20);

    &:hover {
      animation: btn-green .5s forwards;
    }
  }

  &--tabbed-carousel {
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    text-transform: uppercase;
    padding: rem(6) 0;
    letter-spacing: rem(2.4);

    &__active {
      border-bottom: 0.125rem solid $color-white;
    }
  }

  &--resource-list {
    background: transparent;
    color: $color-brand;
    border: 0.063rem solid $color-brand;

    &:hover {
      animation: btn-transparent .5s forwards;
    }
  }

  &--green-underline {
    background-color: transparent;
    color: $color-brand;
    cursor: pointer;
    padding: rem(2);
    text-transform: uppercase;
    letter-spacing: rem(2.4);
    font-size: rem(16);
    font-weight: 600;
    border-bottom: rem(2) solid $color-green;
    width: fit-content;

    &:hover {
      animation: btn-extend-underline .5s forwards;
    }
  }

  &--arrow {
    height: rem(60);
    width: rem(60);
    font-size: rem(16);
    background-color: $color-white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    svg {
      font-size: rem(25);
      margin-right: 0;

      path {
        fill: $color-brand;
      }
    }
  }

  &--shadow {
    filter: drop-shadow(rem(2) rem(2) rem(10) rgba(0, 0, 0, 0.15));
  }

  &--white {
    background-color: $color-white;
    color: $color-brand !important;
    font-family: $text-font-family;
    font-size: rem(16);
    font-weight: 600;
    text-transform: capitalize;
    padding: rem(22);

    &:hover {
      animation: btn-white .5s forwards;
    }
  }

  &--scholarship {
    border-bottom: rem(1) solid $color-green;
    font-family: $text-font-family;
    font-size: rem(16);
    font-style: normal;
    font-weight: 600;
    line-height: rem(20);
    letter-spacing: rem(2.4);
    text-transform: uppercase;
    padding: 1.75rem 0rem 0rem 0rem;
    background-color: transparent;

    &:hover {
      animation: btn-extend-underline .5s forwards;
    }
  }

  &--scholarships--icon {
    display: flex;
    padding: rem(8) rem(14);
    align-items: flex-start;
    gap: rem(5);
    border: rem(1) solid $color-brand;
    background-color: transparent;
    color: $color-brand;
    font-family: $text-font-family;
    font-size: rem(16);
    font-style: normal;
    font-weight: 600;
    line-height: rem(20);
    letter-spacing: rem(2.4);
    text-transform: uppercase;
  }

  &--two-column-icon {
    grid-column: auto;
    display: flex;
    align-items: center;
    gap: rem(15);
    background-color: transparent;
    color: $color-brand;
    font-size: rem(28);
    font-style: normal;
    font-weight: 600;
    line-height: rem(38);
    text-transform: capitalize;
    text-align: left;
    padding: 0;
    position: relative;
    border: none;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: rem(2);
      background-color: $color-dusty-blue;
      transform-origin: center;
      transform: scaleX(0);
      transition: transform 0.3s ease;
    }

    &:hover::after {
      animation: btn-underline 0.3s forwards ease;
    }
  }


  &--intro-h1 {
    color: $color-white;
    font-family: $text-font-family;
    font-size: rem(16);
    font-style: normal;
    font-weight: 600;
    line-height: rem(20);

    &:hover {
      animation: btn-blue .5s forwards;
    }
  }

  &--image-scroller-carousel {
    background: $color-white;
    color: $color-brand;
    font-size: rem(16);
    font-style: normal;
    font-weight: 600;
    line-height: rem(20);

    &:hover {
      animation: btn-white .5s forwards;
    }
  }

  &--h1-intro-one-column {
    display: flex;
    padding: rem(20) rem(30);
    justify-content: center;
    align-items: center;
    gap: rem(10);
    font-size: rem(16);
    font-style: normal;
    font-weight: 600;
    line-height: rem(20);
    text-transform: capitalize;

    &:hover {
      animation: btn-blue .5s forwards;
    }
  }

  &--btn_hover {
    padding: rem(2);
    color: $color-brand;
    background-color: transparent;
    font-size: rem(16);
    font-style: normal;
    font-weight: 600;
    line-height: rem(20);
    letter-spacing: rem(2.4);
    text-transform: uppercase;
    border: none;
    border-bottom: solid rem(2) $color-green;

    &:hover {
      animation: btn-extend-underline .5s forwards;
    }
  }

  &--btn-nav-bar {
    padding: rem(0) !important;
    color: $color-white;
    font-size: rem(13);
    border: none;
  }

  &--connect-with-expert {
    color: $color-white;
    background-color: $color-navy-dark;
    font-family: $text-font-family;
    padding: rem(12) rem(16);
    font-size: rem(14);
    white-space: nowrap;

    &:hover {
      animation: btn-blue .5s forwards;
    }
  }
}


.two-column-button {
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  padding: rem(20) rem(30);
  margin-left: 16.25rem;

  &:hover {
    animation: btn-blue .5s forwards;
  }
}


.two-column-button--color-white {
  background-color: $color-white;
  color: $color-brand;

  &:hover {
    animation: btn-white .5s forwards;
  }
}

.button-accordion {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  border: .0625rem solid $color-brand;
  background: $color-white;
  color: $color-brand;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.25rem;
  letter-spacing: .15rem;
  padding: .5rem .875rem;

  @include tablet {
    display: flex;
    width: 100%;
  }

  &:hover {
    animation: btn-transparent .5s forwards;
  }
}

.button-image-carousel-50-50 {
  background-color: transparent;
  color: $color-brand;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .15rem;
  line-height: 1.25rem;
  padding: .1rem 0;
  border-bottom: .125rem solid transparent;
  border-bottom: rem(2) solid $color-green;
  width: fit-content;

  &:hover {
    animation: btn-extend-underline .5s forwards;
  }

  @include phone {
    font-size: .875rem;
    margin-bottom: .875rem;
  }
}

.button-direction {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0rem 0.125rem .5rem 0rem rgba(0, 0, 0, .2);
  padding: .9rem 1rem;

  svg {
    margin-right: 0rem;
  }

  .svg-inline--fa {
    font-size: 1.6rem;
  }

  &:hover {
    animation: btn-blue .5s forwards;
  }
}

// Deprecated / NOT IN USE
.button-underline {
  color: $color-brand;
  font-family: $text-font-family;
  border-bottom: solid rem(3) transparent;

  &:hover {
    border-bottom: solid rem(1) $color-green;
    padding-bottom: rem(2);
  }
}