@import 'shared/assets/stylesheets/vars.scss';
@import 'shared/assets/stylesheets/mixins.scss';

/* Reset */
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block
}

body {
  line-height: 1
}

ol,
ul {
  list-style: none
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

a {
  color: $color-white;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
}

.rte {
  font: inherit;

  a,
  abbr,
  acronym,
  address,
  applet,
  article,
  aside,
  audio,
  b,
  big,
  blockquote,
  body,
  canvas,
  caption,
  center,
  cite,
  code,
  dd,
  del,
  details,
  dfn,
  div,
  dl,
  dt,
  em,
  embed,
  fieldset,
  figcaption,
  figure,
  footer,
  form,
  // h1,
  // h2,
  // h3,
  // h4,
  // h5,
  // h6,
  header,
  hgroup,
  html,
  i,
  iframe,
  img,
  ins,
  kbd,
  label,
  legend,
  li,
  mark,
  menu,
  nav,
  object,
  ol,
  output,
  // p,
  pre,
  q,
  ruby,
  s,
  samp,
  section,
  small,
  span,
  strike,
  strong,
  sub,
  summary,
  sup,
  table,
  tbody,
  td,
  tfoot,
  th,
  thead,
  time,
  tr,
  tt,
  u,
  ul,
  var,
  video {
    margin: revert;
    padding: revert;
    border: revert;
    font-size: revert;
    font: revert;
    vertical-align: revert;
  }

  h1,
  h2,
  h4 {
    font-family: $heading-font-family;
  }

  h3,
  h5,
  h6 {
    font-family: $text-font-family;
  }

  h1 {
    line-height: rem(64);
    font-size: rem(57);
    font-weight: 700;
    color: $color-white;

    @include after-tablet {
      font-size: rem(80);
    }
  }

  h2 {
    font-weight: bold;
    line-height: rem(44);
    font-size: rem(36);
    color: $color-maroon-dark;
    height: min-content;
    align-self: stretch;

    @include after-tablet {
      font-size: rem(40);
    }
  }

  h3 {
    line-height: rem(36);
    font-weight: 600;
    color: $color-navy-dark;
    font-size: rem(28);
    height: min-content;
    align-self: stretch;
    flex-direction: column;

    @include after-tablet {
      font-size: rem(28);
    }
  }

  h4 {
    font-weight: 600;
    line-height: rem(28);
    color: $color-white;
    font-size: rem(22);

    @include after-tablet {
      font-size: rem(20);
    }
  }

  h5,
  h6 {
    font-weight: 500;
    text-transform: uppercase;
    color: $color-brand;
    line-height: rem(28);
    font-size: rem(18.67);

    @include after-tablet {
      font-size: rem(21.33);
    }
  }

  p {
    font-family: $text-font-family;
    font-weight: normal;
    font-size: rem(16);
    line-height: rem(24);
    color: $color-navy-dark;

    @include after-tablet {
      font-size: rem(16);
    }

  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    direction: ltr;
    text-align-last: left;
    @include adjust-line-height;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    a {
      color: $color-brand;
      font-weight: 600;
      font-size: 28pt;
      font-family: $text-font-family;
      text-decoration: none;

      :hover {
        text-decoration: underline rem(1);
      }
    }
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: revert;
  }

  section {
    text-align-last: center;
  }

  ol,
  ul {
    list-style: revert;
    font-family: $text-font-family;
    font-weight: 400;
    font-size: rem(16);
    line-height: rem(28);
    text-align-last: auto;
    padding-left: 0;
    direction: ltr;
    list-style-position: inside;
    text-indent: -1.4em;
    padding-left: 1.8em;
    margin: rem(0);

    li {
      color: $color-brand;
    }

    li::marker {
      color: $color-brand;
      padding-right: rem(10);
    }
  }

  table {
    border-collapse: revert;
    border-spacing: revert;
  }

  a {
    color: $color-dark-blue-gray;
    text-decoration: none;
    font-weight: bold;
    font-family: $text-font-family;
  }

  a:hover {
    color: $color-navy;
    text-decoration: underline;
  }

  p {
    img {
      max-width: -webkit-fill-available;
      width: inherit;
      height: inherit;
    }
  }

  figure,
  .p {
    float: right;
    max-width: -webkit-fill-available;

    img {
      max-width: -webkit-fill-available;
      width: inherit;
      height: inherit;
    }
  }

  blockquote {
    max-width: 100%;
    margin: 0rem !important;
    padding: rem(80) rem(80) rem(30) rem(80) !important;
    background-color: #002957;
    border-radius: 1.75rem;
    position: relative;

    p:nth-child(1) {
      position: relative;
      bottom: rem(40);
      font-family: $text-font-family;
      font-style: italic;
      line-height: 16pt;
      color: #EAEAEA !important;
    }

    p:nth-child(2) {
      text-align-last: right;
      font-family: $heading-font-family;
      font-weight: 600;
      color: #EAEAEA !important;
    }

    p:nth-child(3) {
      text-align-last: right;
      font-family: $heading-font-family;
      font-weight: 600;
      color: #EAEAEA !important;
    }
  }

  blockquote:before {
    content: "\201C";
    color: $color-green;
    font-family: Arial;
    font-size: 110pt;
    position: absolute;
    font-weight: 700;
    left: rem(4);
    top: rem(5);
  }

  .bitter-semibold {
    font-family: $heading-font-family !important;
    font-weight: 600;
  }

  .open-sans-semibold {
    font-family: $text-font-family !important;
    font-weight: 600;
  }

  .table {
    margin: 0;
    border-top: solid rem(5) $color-brand;
    overflow: scroll;

    colgroup col {
      width: auto !important;
      border-right: rem(1) solid $color-space-gray;
    }

    colgroup col:last-child {
      width: auto !important;
      border-right: none;
    }

    thead {
      th {
        width: rem(416);
        height: rem(60);
        color: $color-brand;
        font-family: $text-font-family;
        font-size: rem(16);
        font-style: normal;
        font-weight: 600;
        line-height: rem(24);
        letter-spacing: rem(0);
        padding: rem(10);
        padding-left: rem(20);
        text-align: left;
      }
    }

    tbody {
      tr {
        border-bottom: rem(1) solid $color-space-gray;
      }

      tr:last-child {
        border-bottom: none;
      }

      th {
        height: rem(76);
        width: rem(416);
        color: $color-brand;
        font-family: $text-font-family;
        font-size: rem(16);
        font-style: normal;
        font-weight: 600;
        line-height: rem(24);
        letter-spacing: rem(0);
        padding: rem(10);
        padding-left: rem(20);
        text-align: left;
      }

      td {
        padding: rem(8);
        padding-left: rem(14);
        text-align: left;
        width: rem(323);
        color: $color-black;
        font-family: $text-font-family;
        font-size: rem(16);
        font-style: normal;
        font-weight: 400;
        line-height: rem(24);
      }
    }

    .ck-table-resized {
      width: 100%;
      border-collapse: collapse;
    }
  }
}

.hide {
  display: none !important;
}

.container {
  margin: 0 auto;
  padding: 0 rem(20);

  @include after-tablet {
    padding: rem(0) rem(0);
  }

  &--small {
    max-width: rem(1180);
  }

  &--large {
    max-width: 100%;
  }

  &--less-padding {

    @include after-tablet {
      padding: 0 rem(20);
    }
  }
}

.component__margin {
  margin: rem(90) 0;
}

/* Background Colors */

/*
  The class names below are derived from the color names in shared/assets/stylesheets/vars.scss.
  They differ from BEM naming convention because strapi converts dashes to underscores.
*/

.background {
  &--padding {
    padding: rem(0) 0;
  }

  &--white {
    background-color: $color-white;
  }

  &--black {
    background-color: $color-black;
  }

  &--red {
    background-color: $color-red;
  }

  &--navy_light {
    background-color: $color-navy-light;
  }

  &--navy_medium {
    background-color: $color-navy-medium;
  }

  &--navy_dark {
    background-color: $color-navy-dark;
  }

  &--maroon_light {
    background-color: $color-maroon-light;
  }

  &--maroon_medium {
    background-color: $color-maroon-medium;
  }

  &--maroon_dark {
    background-color: $color-maroon-dark;
  }

  &--gold_light {
    background-color: $color-gold-light;
  }

  &--gold_medium {
    background-color: $color-gold-medium;
  }

  &--gold_dark {
    background-color: $color-gold-dark;
  }

  &--light_blue {
    background-color: $color-light-blue;
  }
}

.divider {

  &--brand {
    background-color: $color-brand;
  }

  &--malibu_blue {
    background-color: $color-malibu-blue;
  }

  &--green {
    background-color: $color-green;
  }

  &--pumpkin_orange {
    background-color: $color-pumpkin-orange;
  }
}

.fa {
  &--brand {
    color: $color-brand;
  }

  &--black {
    color: $color-black;
  }

  &--red {
    color: $color-red;
  }

  &--river_counties_blue {
    color: $color-river-counties-blue;
  }

  &--river_counties_blue_light {
    color: $color-river-counties-light-blue
  }

  &--gloucester_green {
    color: $color-gloucester-green;
  }

  &--gloucester_green_light {
    color: $color-gloucester-light-green;
  }

  &--mathews_pink {
    color: $color-mathews-pink;
  }

  &--mathews_pink_light {
    color: $color-mathews-light-pink;
  }

  &--avocado_green {
    color: $color-avocado-green;
  }

  &--bubbles {
    color: $color-bubbles;
  }

  &--watermelon_red {
    color: $color-watermelon-red;
  }

  &--dusty_blue {
    color: $color-dusty-blue;
  }

  &--beige {
    color: $color-beige;
  }

  &--jade_green {
    color: $color-jade-green;
  }

  &--weldon_blue {
    color: $color-weldon-blue;
  }

  &--light_blue {
    color: $color-light-blue;
  }

  &--light_orange {
    color: $color-light-orange;
  }

  &--dark_blue_gray {
    color: $color-dark-blue-gray;
  }

  &--gray {
    color: $color-gray;
  }

  &--forest_green {
    color: $color-forest-green;
  }

  &--light_periwinkle {
    color: $color-light-periwinkle;
  }

  &--light_green {
    color: $color-beige;
  }

  &--orange {
    color: $color-pumpkin-orange;
  }

  &--pomegranate_red {
    color: $color-pomegranate-red;
  }

  &--pacific_blue {
    color: $color-pacific-blue;
  }

  &--blue {
    color: $color-blue;
  }

  &--navy {
    color: $color-navy;
  }

  &--ephemeral_blue {
    color: $color-ephemeral-blue;
  }

  &--malibu_blue {
    color: $color-malibu-blue;
  }

  &--green {
    color: $color-green;
  }

  &--pumpkin_orange {
    color: $color-pumpkin-orange;
  }

  &--white {
    color: $color-white;
  }

  &--grass {
    color: $color-grass;
  }

  &--light_grass {
    color: $color-light-grass;
  }
}

.scrollbar {
  &--small {
    &::-webkit-scrollbar {
      width: rem(10);
      height: rem(10);
    }

    &::-webkit-scrollbar-track {
      background-color: #EAEAEA;
      border-radius: rem(96);
      box-shadow: inset 0 0 rem(5) rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888888;
      border-radius: rem(96);
      border: rem(2) solid #EAEAEA; // gives the thumb a bit of padding
      background-clip: content-box; // ensures the background color doesn't bleed beyond the padding

      &:hover {
        background-color: #555555;
      }
    }
  }

  &--large {
    &::-webkit-scrollbar {
      width: rem(42);
      height: rem(42);
      z-index: 100;
    }

    &::-webkit-scrollbar-track {
      background-color: white;
      border-left: rem(1) solid $color-gray;
      border-right: rem(1) solid $color-gray;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-dark-blue-gray;

      &:hover {
        background-color: darken($color-dark-blue-gray, 5%)
      }
    }

    &::-webkit-scrollbar-button {
      display: block;
      background-color: $color-brand;
      border-radius: rem(4);
      height: rem(42);
      width: rem(42);

      // Hide the top up-arrow and the bottom down-arrow for vertical scrollbars
      &:vertical:increment:start,
      &:vertical:decrement:end {
        display: none;
      }

      // Hide the left right-arrow and the right left-arrow for horizontal scrollbars
      &:horizontal:increment:end,
      &:horizontal:decrement:start {
        display: none;
      }

      // Vertical arrows
      &:vertical:increment {
        @include arrow-style(down);
        border-bottom-left-radius: rem(4);
        border-bottom-right-radius: rem(4);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &:vertical:decrement {
        @include arrow-style(up);
        border-top-left-radius: rem(4);
        border-top-right-radius: rem(4);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      // Horizontal arrows
      &:horizontal:increment {
        @include arrow-style(right);
        border-top-right-radius: rem(4);
        border-bottom-right-radius: rem(4);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:horizontal:decrement {
        @include arrow-style(left);
        border-top-left-radius: rem(4);
        border-bottom-left-radius: rem(4);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

  }

}

// This removes the outline for draggable carousels on desktop and mobile
.focusRing___1airF.carousel__slide-focus-ring {
  outline: none !important;
}

/* Headings */
.heading {
  color: $color-brand;
  font-family: $heading-font-family;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 135.714%;
  margin-bottom: rem(11);

  &--h1 {
    font-size: rem(48);
  }

  &--h2 {
    font-size: rem(40);
  }

  &--bitter {
    font-family: $heading-font-family;
  }
}