@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

.footerWrapper {
  display: flex;
  flex-direction: column;
  min-height: rem(292);
  background-color: $color-brand;
  color: $color-white;
  padding: rem(32) rem(64);
  line-height: rem(19.2);
  justify-content: center;
  gap: rem(30);

  &__footer {

    &__img-section {
      flex-direction: column;
      gap: rem(10);
      cursor: pointer;

      img {
        max-width: rem(261);
        height: rem(106);
      }
    }

    &__links {
      display: grid;
      grid-template-columns: 1fr 1fr;

      div {
        padding: rem(10);

        ul {
          li {
            margin-bottom: rem(40);
            position: relative;
            width: fit-content;

            &:after {
              content: '';
              position: absolute;
              left: 0;
              bottom: rem(-10);
              height: rem(3);
              width: 0;
              background-color: $color-white;
              pointer-events: none;
            }

            &:hover:after {
              width: 100%;
              animation: btn-extend-underline-width .3s forwards ease;
            }
          }
        }
      }

      li {
        color: $color-white;
        font-family: $text-font-family;
        font-size: rem(16);
        font-style: normal;
        font-weight: 600;
        line-height: rem(24);
        cursor: pointer;
      }

      a {
        cursor: pointer;
      }
    }
  }

  &__paragraph-section {
    display: flex;
    flex-direction: column;
    gap: rem(20);
    font-family: $text-font-family;

    a {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: rem(-10);
        height: rem(3);
        width: 0;
        background-color: $color-white;
        pointer-events: none;
      }

      &:hover:after {
        width: 100%;
        animation: btn-extend-underline-width .3s forwards ease;
      }
    }
  }

  @include after-tablet {
    display: flex;
    flex-direction: column;
    padding: rem(64) rem(128);

    &__paragraph-section {
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      p {
        color: $color-white;
        font-family: $text-font-family;
        font-size: rem(12);
        font-style: normal;
        font-weight: 400;
        line-height: rem(16);
        letter-spacing: rem(1);
        text-transform: uppercase;
      }
    }

    &__footer {
      display: flex;
      gap: rem(50);

      &__links {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(40);

        div {
          display: flex;
          flex-direction: column;
          gap: rem(40);
          padding: rem(10);
        }
      }
    }
  }
}