@import 'shared/assets/stylesheets/vars.scss';
@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/core.scss';

.accordion {
  display: flex;
  flex-direction: column;
  border-radius: rem(8);

  &__container {
    display: flex;
    flex-direction: column;
    gap: rem(20);
  }

  &__table-container {
    display: flex;
    flex-direction: column;
  }

  &__container2 {
    display: flex;
    flex-direction: column;
    gap: rem(50);
  }

  &__container,
  &__container2 {
    h2 {
      color: $color-brand;
      font-family: $heading-font-family;
      font-size: rem(40);
      font-style: normal;
      font-weight: 500;
      line-height: rem(50);
      letter-spacing: rem(.8);
      margin: 0;

      @include phone {
        font-size: rem(36);
      }
    }

    .description {
      margin-bottom: rem(30);
    }
  }

  &__wrapper {
    border-radius: rem(8);
    padding: rem(0) rem(75);
    margin-bottom: rem(20);

    &BottomBorder {
      border: rem(1) solid #D8DCE6;
    }

    @include after-phone {
      padding: rem(0) rem(150);
    }
  }

  &__button-container {
    display: flex;
    gap: rem(20);

    .button {
      width: fit-content;
    }
  }

  &__container--closed {
    max-width: 73.75rem;
    width: 100%;
  }

  &__container--open {
    max-width: 73.75rem;
    width: 100%;
    border-radius: .5rem;
  }

  &__mega--container {
    display: flex;
    flex-direction: column;
    gap: rem(20);
    border-radius: rem(8);
  }

  &__mega--container__top,
  &__container__top {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: space-between;
    padding: rem(30);
    width: 100%;
    z-index: 2;
    position: relative;
    box-shadow: rem(0) rem(4) rem(20) rem(0) rgba(0, 0, 0, 0.15);
    cursor: pointer;

    h3 {
      font-family: $heading-font-family;
      font-size: 1.34rem;
      line-height: 1.88rem;
      font-weight: 700;
      color: $color-brand;
      margin: 0rem;
    }
  }

  &__container__top {
    background-color: #fff;
  }

  &__mega--container__top {
    flex-direction: column;
    height: fit-content;
    gap: rem(40);

    &--description {
      display: flex;
      flex-direction: column;
      gap: rem(8);
      width: 100%;
    }

    .imgWrapper {
      height: rem(200);
      max-width: rem(276);
      flex-shrink: none;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 0.5rem;
      }
    }

    h3 {
      font-family: Open Sans;
      font-size: rem(28);
      font-style: normal;
      font-weight: 600;
      line-height: 135.714%;
      text-transform: capitalize;
      margin: 0;
    }

    h4 {
      font-family: $text-font-family;
      font-size: rem(16);
      font-style: normal;
      font-weight: 600;
      line-height: 175%;
      text-transform: uppercase;
      margin: 0;
    }
  }

  &__icon {
    display: flex;
    justify-content: end;

    svg {
      font-size: 28px;
      font-weight: 400;

      path {
        fill: $color-brand;
      }
    }
  }

  &__mega--container__bottom,
  &__container__bottom {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: rem(8);
    z-index: 1;
    position: relative;

    &--desktopImages {
      display: none;
      padding-left: rem(30);
      padding-right: rem(30);
      margin-top: rem(10);
      margin-bottom: rem(30);
    }

    &--content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: rem(20);
      margin-bottom: rem(20);
    }

    h3 {
      font-family: $heading-font-family;
      font-size: 1.34rem;
      line-height: 1.88rem;
      font-weight: 700;
      color: $color-brand;
      margin: 0;
    }

    h4 {
      font-family: $heading-font-family;
      font-size: rem(20);
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      color: $color-brand;
      margin: 0;
    }

    h5 {
      font-family: $text-font-family;
      font-size: rem(16);
      font-style: normal;
      font-weight: 600;
      line-height: 175%;
      text-transform: uppercase;
      color: $color-brand;
      margin: 0;
      margin-top: rem(1);
    }

    .accordion__bottomHeaders {
      display: flex;
      flex-direction: column;
      gap: rem(5);
      margin-bottom: rem(8);
    }
  }

  &__container__bottom {
    padding: rem(35) rem(23) rem(25) rem(23);
    margin-top: rem(-10);
    gap: rem(20);
  }

  &__container__table-bottom {
    align-items: center;
    box-shadow: rem(0) rem(4) rem(20) rem(0) rgba(0, 0, 0, 0.15);
    border-bottom-left-radius: rem(8);
    border-bottom-right-radius: rem(8);
  }

  &__mega--container__bottom {
    background: none;
    margin-bottom: rem(30);

    &--content {
      padding-left: rem(30);
      padding-right: rem(30);
    }

    .accordion__button-container {
      padding-top: rem(20);
      align-items: center;
      justify-content: center;
    }
  }

  &__carousel {

    &__inner-slide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.938rem;
    }

    .imgWrapper {
      width: fit-content;
      height: 12.375rem;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 0.5rem;
      }
    }

    &--dot-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.813rem;
      margin-top: rem(30);
      margin-bottom: rem(10);
    }

    .carousel__dot {
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      border: none;
      box-shadow: 0rem 0.125rem .5rem 0rem rgba(0, 0, 0, .2);
      margin: .4rem;
    }

    .carousel__dot--selected {
      background-color: $color-brand;
    }
  }


  @include after-phone {

    &__container__top {
      flex-direction: row;
    }

    &__mega--container__top {
      flex-direction: row;

      .imgWrapper {
        flex-shrink: .5;
      }
    }

    &__button-container {
      flex-direction: column;
      // align-items: center;
    }

    &__mega--container__bottom,
    &__container__bottom {
      flex-direction: column;
      // align-items: center;
      // gap: rem(20);
      // padding: rem(28) rem(50) rem(30) rem(50);
      // margin-bottom: rem(30);

      &--carousel {
        display: none;
      }

      &--desktopImages {
        display: flex;
        gap: rem(20);
        max-width: 100%;
        justify-content: center;
      }

      .imgWrapper {
        width: rem(350);
        height: rem(217);

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 0.5rem;
        }
      }

      .accordion__bottomHeaders {
        flex-direction: row;
        gap: rem(20);

      }
    }

    &__container__bottom {
      padding: rem(28) rem(50) rem(30) rem(50);
    }

    &__mega--container__bottom {
      &--content {
        padding-left: rem(80);
        padding-right: rem(80);
      }

      .imgWrapper {
        width: 100%;
        max-width: rem(500);
      }
    }
  }

  @include after-tablet {

    &__mega--container__top {
      .imgWrapper {
        flex-shrink: 0;
      }
    }

    &__container__bottom {
      flex-direction: column;
      // align-items: center;

      &:first-of-type {
        padding-top: 0;
      }

      &--desktopImages {
        display: flex;
        gap: 1.875rem;
        max-width: 100%;
      }

      .imgWrapper {
        width: 18.813rem;
        height: 14.375rem;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 0.5rem;
        }
      }
    }

    &__mega--container__bottom {
      &--content {
        padding-left: rem(80);
        padding-right: rem(80);
      }
    }
  }
}

.two-column-accordion-layout {
  display: flex;
  justify-content: space-around;
  max-width: 100%;
  gap: rem(47);

  &__accordions {
    display: flex;
    flex-direction: column;
    gap: rem(20);
  }

  .accordion {
    flex-direction: row;

    &__wrapper {
      width: 100%;

      &TopBorder {
        border: rem(1) solid #D8DCE6;
      }

      &BottomBorder {
        border: rem(1) solid #D8DCE6;
      }
    }

    &__container__top {
      height: auto;
    }
  }


  &__subHeading {
    color: $color-brand;
    font-family: $text-font-family;
    font-size: rem(28);
    font-style: normal;
    font-weight: 600;
    line-height: rem(38);
    text-transform: capitalize;
    margin: 0;

    @include phone {
      font-size: rem(24);
    }
  }

  &__column {

    &--content {
      display: flex;
      flex-direction: column;
      gap: rem(25);
      width: 70%;
    }

    &--image {
      width: 30%;
    }
  }

  &__mobileImageWrapper {
    width: rem(348);
    height: rem(202);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: rem(8);
    }
  }

  &__desktopImageWrapper {
    height: rem(319);
    max-width: rem(443);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: rem(8);
    }
  }

  .mobile-show {
    display: none;
  }

  .desktop-show {
    display: block;
  }

  @include phone-landscape {
    &__column {
      &--content {
        width: 100%;
        gap: rem(20);
      }

      &--image {
        display: none;
      }
    }

    .mobile-show {
      display: block;
    }

    .desktop-show {
      display: none;
    }
  }

  &__reversed {
    flex-direction: row-reverse;
  }
}

.simple-accordion {
  &__item {
    line-height: rem(35);

    .item-title {
      color: $color-brand;
      display: flex;
      justify-content: space-between;

      &__link {
        color: $color-brand;
      }
    }

    .item-body {
      padding-left: 1rem;

      a {
        color: $color-brand;
      }
    }
  }
}

.table-accordion {
  border-top-left-radius: rem(8);
  border-top-right-radius: rem(8);
}