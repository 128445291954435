@import 'shared/assets/stylesheets/mixins.scss';
@import 'shared/assets/stylesheets/vars.scss';

.algolia-filtered-list,
.filtered-list {
	display: flex;
	flex-direction: column;

	&__results-info {
		margin-top: 1rem;
		text-align: right;
	}

	&__items {
		margin-top: 1rem;
	}

	&__item {
		padding: rem(30) 0;
		transition: opacity 0.2s ease, box-shadow 0.2s ease;
		opacity: 1;
		cursor: pointer;
		box-shadow: 0 0 rem(4) rgba(0, 0, 0, 0); // Initial state with no shadow

		&:hover {

			.algolia-filtered-list__itemContentTitle,
			.filtered-list__itemContentTitle {
				text-decoration: underline;
			}

			box-shadow: 0 rem(2) rem(6) rgba(0, 0, 0, 0.1); // Slight shadow for depth
		}

		&:nth-child(odd) {
			background: rgba(216, 220, 230, 0.20);
		}

		.container {
			display: flex;
			align-items: center;
			padding-left: rem(20);

			@include phone {
				flex-direction: column;
				align-items: flex-start;
			}
		}

		&ImgWrapper {
			width: rem(170);
			flex-shrink: 0;

			img {
				width: 100%;
				object-fit: cover;
				border-radius: rem(4);
				height: rem(113);
			}

			@include phone {
				height: rem(217);
				width: 100%;
				margin-bottom: rem(20);

				img {
					height: 100%;
				}
			}
		}

		&Content {
			padding-left: rem(70);

			&Title {
				font-family: $heading-font-family;
				font-size: rem(20);
				font-style: normal;
				font-weight: 700;
				line-height: 150%;
				letter-spacing: rem(0.4);
				color: $color-brand;
				display: flex;
				gap: rem(15);
				margin-bottom: rem(5);
			}

			p {
				font-family: $text-font-family;
				color: $color-navy;
				font-size: rem(16);
				font-style: normal;
				font-weight: 400;
				line-height: 175%;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				/* Number of lines you want to display */
				overflow: hidden;
				text-overflow: ellipsis;
				word-wrap: break-word;
			}

			&Type,
			.highlighted-content__value {
				color: $color-brand;
				font-family: $text-font-family;
				font-size: rem(16);
				font-style: normal;
				font-weight: 600;
				line-height: 175%;
				letter-spacing: rem(2.4);
				text-transform: uppercase;
			}

			@include phone {
				padding: 0;
			}
		}
	}

	&--inputs {
		top: 0;
		justify-content: center;
		// align-items: center;
		width: 100%;
		background-color: $color-azureish-white;
		padding-top: rem(40);
		padding-bottom: rem(40);

		@include tablet {
			padding: rem(20) 0;
		}

		.container {
			display: grid;
			gap: rem(30);
			grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
			justify-content: center;
			padding: rem(0) rem(20);

			@include tablet {
				gap: rem(20);
			}
		}
	}

	&__pagination {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $color-azureish-white;
		padding: rem(30) 0;

		.container {
			display: flex;
		}

		&Btn {
			border: none;
			background: none;
			font-family: $heading-font-family;
			font-size: rem(20);
			font-style: normal;
			font-weight: 700;
			line-height: 120%;
			letter-spacing: 0.4px;
			color: $color-brand;
			padding: 0 rem(5);
			cursor: pointer;
			margin: 0 rem(5);
		}

		&Ellipsis {
			padding: 0;
			margin: 0;
		}

		&ArrowLeft,
		&ArrowRight {
			background: none;
			border: none;
			font-size: rem(20);

			&:not(:disabled) {
				cursor: pointer;
			}
		}

		&ArrowLeft {
			margin-right: rem(10);
		}

		&ArrowRight {
			margin-left: rem(10);
		}

		.active {
			position: relative;

			&::after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: rem(-2);
				height: 2px;
				background: currentColor;
			}
		}
	}

	&__results {
		&Mobile {
			height: auto;
			display: none;
			margin-top: rem(20);

			@include desktop {
				display: flex;
			}
		}

		&Desktop {
			display: flex;
			justify-content: flex-end;
			margin-top: rem(50);
			height: rem(55);

			@include desktop {
				display: none;
			}
		}

		&Mobile,
		&Desktop {
			font-family: $text-font-family;
			font-size: rem(16);
			font-style: normal;
			font-weight: 400;
			line-height: 175%;

			@include desktop {
				justify-content: flex-start;
			}
		}
	}


	@keyframes shimmer {
		0% {
			background-position: 200% 0;
		}

		100% {
			background-position: -200% 0;
		}
	}

	&__skeleton-loader {
		margin-top: 10px;
		padding: 20px;
	}

	&__skeleton-item {
		width: 100%;
		height: 100px;
		background: #f0f0f0;
		background-image: linear-gradient(to right, #f0f0f0 0%, #e0e0e0 20%, #f0f0f0 40%, #f0f0f0 100%);
		background-repeat: no-repeat;
		background-size: 200% 100%;
		/* Adjust background-size to cover the full width */
		display: flex;
		align-items: center;
		border-radius: rem(4);
		animation: shimmer 1.5s infinite linear;
		margin-bottom: 10px;
	}

	&__skeleton-content {
		width: 100%;
		height: 80px;
		border-radius: rem(4);
		background: #fff;
		transform: scale(1);
		animation: pulse 1.5s infinite ease-in-out;
	}

	@keyframes pulse {
		0% {
			transform: scale(1);
		}

		50% {
			transform: scale(1.02);
		}

		100% {
			transform: scale(1);
		}
	}

	&__noResults {
		padding-top: rem(50);
		padding-bottom: rem(50);
		text-align: center;

		h3 {
			font-family: $heading-font-family;
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			line-height: 150%;
			letter-spacing: 0.4px;
			color: $color-ephemeral-blue;
			gap: rem(15);
			margin-bottom: rem(5);
		}

		p {
			font-family: $text-font-family;
			font-size: rem(16);
			font-style: normal;
			font-weight: 400;
			line-height: 175%;
		}
	}
}

.algolia-filtered-list {
	&__item {
		&Content {
			padding-left: 0;

			&Title {
				font-weight: 500;
				gap: 0;
				display: inline;
			}

			em {
				font-weight: bold;
			}
		}
	}

	.highlighted-content {
		display: flex;
		flex-direction: column;
		gap: rem(10);
		font-size: rem(15) !important;
		margin-top: rem(10);

		p,
		span,
		h1,
		h3,
		h4,
		h5,
		h6 {
			font-size: rem(15) !important;
			color: $color-black !important;
			letter-spacing: normal;
			font-weight: 400;
			text-transform: none;
			background-color: transparent !important;
		}

		p,
		figure {

			img,
			.video-player,
			.video-player--vimeo,
			iframe {
				display: none;
			}
		}
	}
}

.search-form {
	width: 100%;
	margin-bottom: rem(20);
	position: relative;

	input {
		width: calc(100% - 34px);
		border-radius: rem(10);
		color: $color-placeholder-text;
		outline: none;
		border: none;
		padding: 8px 17px;
	}

	&--header-mobile,
	&--footer {
		margin-bottom: 0;

		svg {
			position: absolute;

			path {
				fill: $color-solid-gray;
			}
		}
	}

	&--header-mobile {
		padding: rem(20);

		svg {
			top: rem(35);
			left: rem(35)
		}

		input {
			padding: 15px 17px 15px 45px;
			border-radius: rem(4);
			//40 (padding) + 45 (left) + 17 (right)
			width: calc(100% - 102px);
		}
	}

	&--footer {
		svg {
			top: rem(8);
			left: rem(8);
		}

		input {
			border-radius: 50px;
			max-width: rem(350);
			padding-left: 30px;
			width: calc(100% - 47px)
		}
	}
}